import { IResponse } from '~/apis/axios/config'
import request from '~/apis/axios'
import type {
  CommonBrandInfoResult,
  CommonChildrenInfoParam,
  CommonChildrenInfoResult,
  LoginChildrenInfoParam,
  LoginChildrenInfoResult,
  MainChildrenInfoParam,
  MainLiveInfoParam,
  MainLiveInfoResult,
  MainLiveInfoUseBusParam,
  MainLiveInfoUseBusResult,
  MainTotalInfoParam,
  MainTotalInfoResult,
} from '~/apis/main/types'

export const commonChildrenInfo = (
  param: CommonChildrenInfoParam
): Promise<IResponse<CommonChildrenInfoResult>> => {
  return request.get({ url: '/api/common/commonChildrenInfo', params: param })
}

export const loginChildrenInfo = (
  param: LoginChildrenInfoParam
): Promise<IResponse<LoginChildrenInfoResult>> => {
  return request.get({ url: '/api/login/loginChildrenInfo', params: param })
}

export const commonBrandInfo = (): Promise<
  IResponse<CommonBrandInfoResult>
> => {
  return request.get({ url: '/api/common/commonBrandInfo' })
}

export const mainChildrenInfo = (
  param: MainChildrenInfoParam
): Promise<IResponse<CommonBrandInfoResult>> => {
  return request.put({ url: '/api/main/mainChildrenInfo', params: param })
}

export const mainTotalInfo = (
  param: MainTotalInfoParam
): Promise<IResponse<MainTotalInfoResult>> => {
  return request.get({ url: '/api/main/mainTotalInfo', params: param })
}

export const mainLiveInfo = (
  param: MainLiveInfoParam
): Promise<IResponse<MainLiveInfoResult>> => {
  return request.get({ url: '/api/main/mainLiveInfo', params: param })
}

/*
 *
 *
 * */
export const mainLiveInfoUseBus = (
  param: MainLiveInfoUseBusParam
): Promise<IResponse<MainLiveInfoUseBusResult>> => {
  return request.post({ url: '/api/main/mainLiveInfo', params: param })
}
